@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Corben&family=Erica+One&family=Ubuntu&display=swap');

@font-face {
  font-family: BitSumishiPro;
  src: url('../public/fonts/BitsumishiProBook.otf');
  font-weight: bold;
}

@font-face {
  font-family: Votage;
  src: url('../public/fonts/Votage.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'DejavuSansMono';
  src: url('../public/fonts/dejavu-sans-mono.ttf') format('truetype');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

html {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><circle cx='12' cy='12' r='5' fill='%2300ff9d' style='filter: blur(2px);'/></svg>") 12 12, auto;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #1f1f1f;
}

.font {
  font-family: 'Votage', sans-serif;
}

a {
  color: #e0e0e0;
}

a {
  pointer-events: all;
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

svg {
  fill: #e0e0e0;
  width: 52px !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 1 !important;
  touch-action: none;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1;
  animation: fade-in 5s ease 1s forwards;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0;
  overflow: hidden;
}

li {
  display: block;
  overflow: hidden;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15vh 20px 10vh;
  pointer-events: none;
  z-index: 2;
}

.main-content {
  text-align: center;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.secondary-content {
  text-align: center;
  margin-top: auto;
  pointer-events: all;
}

.info p {
  font-size: 16px;
  line-height: 1.25em;
  margin-bottom: 0.2em;
  color: #e0e0e0;
}

.accent {
  -webkit-text-stroke: 1px #e0e0e0;
  color: transparent;
}

.price {
  display: inline-block;
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.info h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #e0e0e0;
}

.info h3 {
  font-weight: 800;
  font-size: 5em;
  letter-spacing: -4px;
  line-height: 1em;
  margin: 0;
  color: #e0e0e0;
}

.info h4 {
  font-weight: 300;
  font-size: 1.15em;
  letter-spacing: -1px;
  margin: 10px 0;
  color: #e0e0e0;
}

@keyframes cursor-pulse {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 2px #00ff9d);
  }
  50% {
    transform: scale(1.1);
    filter: drop-shadow(0 0 5px #00ff9d);
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 2px #00ff9d);
  }
}

/* Update the hover cursor style */
a:hover, button:hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'><path d='M9,20.5v-7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v7c0,0.8-0.7,1.5-1.5,1.5S9,21.3,9,20.5z M13,20.5v-7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v7c0,0.8-0.7,1.5-1.5,1.5S13,21.3,13,20.5z M17,20.5v-7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v7c0,0.8-0.7,1.5-1.5,1.5S17,21.3,17,20.5z M21,20.5v-7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v7c0,0.8-0.7,1.5-1.5,1.5S21,21.3,21,20.5z' fill='%2300ff9d' style='filter: blur(1px);'/></svg>") 7 0, pointer;
}

/* Update the mask hover cursor animation */
.info:hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'><style>@keyframes loading { 0% { stroke-dashoffset: 60; transform: rotate(0deg); } 100% { stroke-dashoffset: 0; transform: rotate(360deg); } } .loader { animation: loading 2s linear infinite; transform-origin: center; }</style><circle cx='24' cy='24' r='12' fill='%2300ff9d' style='filter: blur(2px);'/><circle class='loader' cx='24' cy='24' r='16' stroke='%2300ff9d' stroke-width='2' fill='none' stroke-dasharray='20'/></svg>") 24 24, pointer;
}

/* Add these styles */
.cursor-trail {
  pointer-events: none;
  position: fixed;
  width: 6px;
  height: 6px;
  background: #00ff9d;
  border-radius: 50%;
  opacity: 0;
  z-index: 10000;
  filter: blur(2px);
  animation: trail-fade 0.5s linear forwards;
}

@keyframes trail-fade {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

/* Add these new styles */
.cursor-explosion {
  pointer-events: none;
  position: fixed;
  width: 6px;
  height: 6px;
  background: #00ff9d;
  border-radius: 50%;
  z-index: 10000;
  filter: blur(2px) brightness(2);
  animation: explosion 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  box-shadow:
    0 0 15px #00ff9d,
    0 0 30px #00ff9d,
    0 0 45px rgba(0, 255, 157, 0.5);
}

@keyframes explosion {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
    filter: blur(2px) brightness(2);
    background: #00ff9d;
  }
  50% {
    opacity: 0.8;
    filter: blur(3px) brightness(3);
    background: #00ff9d;
  }
  100% {
    opacity: 0;
    transform:
      translate(
        calc(cos(var(--angle)) * 50px),
        calc(sin(var(--angle)) * 50px)
      )
      scale(0.1);
    filter: blur(4px) brightness(2);
    background: #00ff9d;
  }
}

/* Add this new keyframe animation */
@keyframes cursor-impact {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 2px #00ff9d);
  }
  50% {
    transform: scale(0.7);
    filter: drop-shadow(0 0 8px #00ff9d);
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 2px #00ff9d);
  }
}

/* Remove this class as we're handling the animation within the SVG */
.cursor-clicking {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-content {
  background: rgba(0, 0, 0, 0.95);
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 255, 157, 0.3);
  min-width: 600px;
  max-width: 700px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.1);
  position: relative;
}

.agent-showcase {
  display: flex;
    flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  width: 100%;
}

.agent-card {
  position: relative;
  cursor: pointer;
  padding: 1.5rem;
  border: 1px solid rgba(0, 255, 157, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  color: #00ff9d;
  transition: all 0.2s ease;
  overflow: hidden;
  text-align: center;
  min-width: 150px;
}

.agent-card:hover {
  border-color: rgba(0, 255, 157, 0.8);
  box-shadow: 0 0 20px rgba(0, 255, 157, 0.2);
  transform: translateY(-2px);
}

.agent-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 255, 157, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.agent-card:hover::before {
  opacity: 1;
}

.agent-card.active {
  border-color: rgba(0, 255, 157, 1);
  box-shadow: 0 0 30px rgba(0, 255, 157, 0.3);
  background: rgba(0, 0, 0, 0.5);
}

.interactive-card {
  flex: 0 1 150px;
  padding: 15px;
  transform-style: preserve-3d;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(31, 31, 31, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 255, 157, 0.3);
  border-radius: 10px;
  position: relative;
  height: 50px;
  overflow: hidden;
  z-index: 3;
  pointer-events: all;
}

.interactive-card:hover {
  transform: scale(1.05);
  border-color: rgba(0, 255, 157, 0.8);
  height: auto;
}

.interactive-card h3 {
  color: #00ff9d;
  margin: 0;
  font-weight: 600;
  font-size: 1.1em;
  letter-spacing: 0.5px;
  transition: opacity 0.3s ease;
}

.hover-text {
  color: #00ff9d;
  font-size: 0.85em;
  margin: 8px 0 0;
  font-weight: 400;
  letter-spacing: 0.3px;
  opacity: 0;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.interactive-card:hover .hover-text {
  opacity: 1;
  animation: fadeIn 0.3s ease forwards;
}


.typewriter {
  font-size: 1.2em;
  color: #e0e0e0;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  overflow: hidden;
  border-right: 0.15em solid #00ff9d;
  white-space: nowrap;
  margin: 1em auto 0;
  animation: typing 3.5s steps(40, end),
             blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #00ff9d }
}

.footer-left {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 13px;
  color: #e0e0e0
}

.footer-right {
  position: absolute;
  bottom: 40px;
  right: 40px;
  font-size: 13px;
  color: #e0e0e0
}

.footer-text {
  font-family: 'DejavuSansMono', monospace;
  font-size: 14px;
}

.box-elem {
  pointer-events: none;
  min-width: 400px;
}

@media only screen and (max-width: 768px) {
  .box-elem {
    min-width: 350px;
  }

  .footer-right {
    bottom: 10px;
  }
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 255, 157, 0.4); }
  70% { box-shadow: 0 0 0 20px rgba(0, 255, 157, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 255, 157, 0); }
}

@keyframes glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-2px, 2px); }
  40% { transform: translate(-2px, -2px); }
  60% { transform: translate(2px, 2px); }
  80% { transform: translate(2px, -2px); }
  100% { transform: translate(0); }
}

.floating-element {
  animation: float 6s ease-in-out infinite;
}

.glow-effect {
  position: relative;
}

.glow-effect::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00ff9d, #00804d);
  border-radius: inherit;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
  animation: pulse 2s infinite;
}

.choose-path {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  pointer-events: all;
  animation: float 3s ease-in-out infinite;
}

.path-text {
  color: #00ff9d;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  position: relative;
}

.path-text::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #00ff9d;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.choose-path:hover .path-text::after {
  transform: scaleX(1);
  transform-origin: left;
}

.arrow {
  color: #00ff9d;
  font-size: 1.5rem;
  transform: translateX(0);
  transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.choose-path:hover .arrow.left {
  transform: translateX(-0.5rem);
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.5);
}

.choose-path:hover .arrow.right {
  transform: translateX(0.5rem);
  text-shadow: 0 0 10px rgba(0, 255, 157, 0.5);
}

@media only screen and (max-width: 768px) {
  .overlay-content {
    min-width: 350px;
    max-width: 350px;
    padding: 0;
  }
}

.terminal-window {
  width: 100%;
  max-width: 56rem;
  margin: 0 auto;
  padding: 1rem;
}

.terminal-content {
  background: #000;
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: 'DejavuSansMono', monospace;
  font-size: 0.875rem;
  border: 1px solid #333;
}

.terminal-comment {
  color: #6b7280;
}

.terminal-code {
  color: #ffffff;
}

.terminal-output {
  color: #00ff9d;
  padding-left: 2rem;
}

.terminal-region {
  color: #6b7280;
}

.terminal-error {
  color: #ff0000;
}
